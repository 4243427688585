// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/home/millionproduct/www/code/front-end-code/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sc-js": () => import("/home/millionproduct/www/code/front-end-code/src/pages/sc.js" /* webpackChunkName: "component---src-pages-sc-js" */),
  "component---src-pages-tc-js": () => import("/home/millionproduct/www/code/front-end-code/src/pages/tc.js" /* webpackChunkName: "component---src-pages-tc-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/millionproduct/www/code/front-end-code/.cache/data.json")

